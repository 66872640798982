<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <div class="text-center">
          <b-card-text class="mb-2">
            <div class="row mb-2">
              <div class="col">
                <img
                  src="https://cloudfront-us-east-2.images.arcpublishing.com/reuters/XR46XDPWSNIENH4J37JZ7SSOOU.jpg"
                  width="100%"
                  height="100%"
                />
              </div>
            </div>
            <h4>Apple.com</h4>
          </b-card-text>
        </div>
        <!-- พนักงาน staff@test.com -->

        <!-- form -->
        <validation-observer ref="loginForm" #default="{ invalid }">
          <b-form class="auth-login-form mt-2" @submit.prevent="validationForm">
            <!-- email -->
            <b-form-group label-for="email" label="Email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="form.email"
                  name="login-email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="steve@lenslineup.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <b-link :to="{ name: 'auth-forgot-password-v1' }">
                  <!-- <small>ลืมรหัสผ่าน?</small> -->
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="form.password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              @submit.prevent="validationForm"
            >
              เข้าสู่ระบบ
            </b-button>
          </b-form>
        </validation-observer>

        <!-- <b-card-text class="text-center mt-2">
          <span>ยังไม่เคยลงทะเบียน? </span>
          <b-link :to="{ name: 'register' }">
            <span>สมัครสมาชิกฟรี </span>
          </b-link>
        </b-card-text> -->

        <!-- <div class="divider my-2">
          <div class="divider-text">หรือเข้าสู่ระบบโดยล็อคอินผ่าน</div>
        </div> -->

        <!-- social button d-flex -->
        <!-- <div class="justify-content-center">
          <b-button block variant="facebook" class="mb-1">
            <feather-icon icon="FacebookIcon" />
            Log in with Facebook
          </b-button>
          <b-button block variant="google">
            <feather-icon icon="MailIcon" />
            Sign in with Google
          </b-button>
        </div> -->
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import useJwt from "@/auth/jwt/useJwt";
import * as axios from "axios";

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      wrong_password: false,
      status: "",
      form: {
        email: null,
        password: null,
      },
      // validation rules
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    test() {
      useJwt.login(this.form).then((response) => {
        console.log(response);

        const res = response.data;
        useJwt.setToken(res.data.user.access_token);
        // useJwt.setRefreshToken(response.data.refreshToken)
        // localStorage.setItem('userData', JSON.stringify(res.data))
      });
    },
    validationForm() {
      this.$refs.loginForm.validate().then((success) => {
        console.log(success);
        if (success) {
          this.$http({
            method: "POST",
            // url: `https://157.230.38.225/api/auth/login/email`,
            // url: `http://localhost:3080/api/auth/login/email`,
            url: `https://api.lenslineup.com/auth/login/email`,

            data: this.form,
          }).then((x) => {
            // $cookies.set('token',x.data.accessToken)
            const res = x.data;
            if (res.success) {
              // $cookies.set('accessToken',x.data.user.access_token)
              localStorage.setItem("accessToken", res.data.user.access_token);
              this.$router.push({ name: "home" });
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.success ? "สวัสดี" : "มีข้อผิดพลาด",
                icon: res.success ? "CheckIcon" : "XIcon",
                text: res.success ? "เข้าสู่ระบบสำเร็จ" : res.message,
                variant: res.success ? "success" : "danger",
              },
            });
            // second-page
            // useJwt.setToken(response.data.accessToken)
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
